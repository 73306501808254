import gameNote from "./gameNote";

export default class games {
  gamesList: gameNote[] = [
    new gameNote(
      "Beyond Good & Evil 20th Anniversary Edition",
      3,
      "http://store-images.s-microsoft.com/image/apps.1213.14440741234009698.2baf35ea-9353-48f1-af45-b2391a9da332.fbed7519-e704-4d32-be58-5ed20884f2fb",
      "https://www.trueachievements.com/game/Beyond-Good-and-Evil-20th-Anniversary-Edition/achievements",
      "Beyond-Good-and-Evil-20th-Anniversary-Edition",
      false,
      false,
      false,
      false,
      [
        "Requires at least two playthroughs since speedrun mode is missing one of the MDisks",
        "Be aware of the many missable achievements and once you fly to the moon it's a point of no return and you can't go back",
        "Guide for all pearls, animals, PA Hearts and MDisks",
        "https://www.youtube.com/watch?v=9RMoGU-pONA",
      ]
    ),
    new gameNote(
      "Rolling Hills: Make Sushi, Make Friends",
      2,
      "http://store-images.s-microsoft.com/image/apps.63128.13987004839193022.e60f93d4-33b6-450a-9e73-fd2c1a2733ce.98ab9867-d965-4c1b-94d4-742f78552cff",
      "https://www.trueachievements.com/game/Rolling-Hills-Make-Sushi-Make-Friends/achievements",
      "Rolling-Hills-Make-Sushi-Make-Friends",
      false,
      false,
      false,
      false,
      [
        "Once the special daily bonus level is available at the statue you gain XP way faster than playing normally.  Remove all the tables in your restaurant and just do the daily to level up",
        "Focus on leveling Maia's friendship to max first since you unlock 'Feast' which gives an entire friendship level per meeting making the process of maxing out all friendships much faster",
      ]
    ),
    new gameNote(
      "Senua’s Saga: Hellblade II",
      2,
      "http://store-images.s-microsoft.com/image/apps.57396.14301289549560004.6fe923e6-edd9-4677-9763-5ea1512de41b.a437513f-a4ef-400b-bfd7-bc73c8ecf1af",
      "https://www.trueachievements.com/game/Senuas-Saga-Hellblade-II/achievements",
      "Senuas-Saga-Hellblade-II",
      false,
      false,
      false,
      false,
      [
        "Can be done in ~5 hrs with a guide.  Any collectibles missed can be chapter selected",
        "Guide I used",
        "https://www.youtube.com/watch?v=jq-VSMrG408",
      ]
    ),
    new gameNote(
      "PING REDUX",
      1,
      "http://store-images.s-microsoft.com/image/apps.31973.70793517482886028.8b74f927-f090-4f70-b854-3e8bf0b89b5a.db06b06f-fbd5-4c7a-9b4d-991f4894b203",
      "https://www.trueachievements.com/game/PING-REDUX/achievements",
      "PING REDUX",
      false,
      false,
      false,
      false,
      [
        "Once you reach 198 stars you'll unlock all levels so you can just skip the remaining levels and complete the bossfights",
      ]
    ),
    new gameNote(
      "Fallout 76",
      4,
      "http://store-images.s-microsoft.com/image/apps.50740.13657086471689364.807880c3-cb25-41b7-b7e9-1ccb156166f4.b2a7e643-56d1-4edf-9c4b-27c0085518a7",
      "https://www.trueachievements.com/game/Fallout-76/achievements",
      "Fallout-76",
      false,
      false,
      false,
      false,
      [
        "Very time consuming but not difficult for the most part.  Didn't have issues with bugs with one exception",
        "The Buried Treasure Quest (Go for the Gold Achievement) is infamous for how buggy it is and took me close to an hour to finally get through it.  Unforunately it seems like it's more luck than anything but you will find numerous Steam forum posts and YouTube videos about how buggy this quest is and how people got it to work",
        "The only mission that was truely difficult was 'I Am Become Death' due to how long it is and how much ammo you use killing all the enemies.  If you have a friend with a decent character it can help a ton",
        "Has a few multiplayer achievements that can be boosted easily",
        "The achievement for getting max raider reputation can be cheesed but the max foundation reputation can't.  Get started with this achievement as soon as you can otherwise you will have to log in daily for over 30+ days doing the foundation daily quest over and over",
        "Expeditions are best source of ammo by far - take advantage of it",
        "Gear and weapons are capped at level 50 meaning once you hit around level ~65 your build will start to fall off pretty hard unless you min-max your perks around a specific weapon build.  I'd recommend an automatic build using 'The Fixer' which is a legendary weapon unlocked as part of the main questline",
        "Despite being strong early game shotguns are one of the worst late game weapons to build around and most recommended builds online avoid it all together",
      ]
    ),
    new gameNote(
      "Persona 5 Tactica",
      3,
      "http://store-images.s-microsoft.com/image/apps.44242.14461424539776518.be5fa828-401b-4f0c-ac86-920d7dcbfada.d932d547-ff13-4f90-9344-d1b5e6fac6cf",
      "https://www.trueachievements.com/game/Persona-5-Tactica/achievements",
      "Persona-5-Tactica",
      false,
      false,
      false,
      false,
      [
        "Has no difficulty achievements which means you can breeze through on the easiest difficulty",
        "Getting 100% of the persona combinations will be a bit of a pain and requires a partial NG+ playthrough",
      ]
    ),
    new gameNote(
      "DeathSpank",
      2,
      "https://i.imgur.com/OLhsze5.png",
      "https://www.trueachievements.com/game/DeathSpank/achievements",
      "DeathSpank",
      false,
      false,
      false,
      false,
      [
        "Fairly easy ARPG but the twist is that the block mechanic is pretty important for dealing large amounts of damage and staying alive",
      ]
    ),
    new gameNote(
      "Fallout Shelter",
      2,
      "https://i.imgur.com/YSCkSLL.jpeg",
      "https://www.trueachievements.com/game/Fallout-Shelter/achievements",
      "Fallout-Shelter",
      false,
      false,
      false,
      false,
      [
        "PC version can be cheesed by manipulating date/time.  Some achievements can't be manipulated using this trick (For example, 50 Raider Attacks) so even using the trick you are looking at ~75 hours with a significant portion just being AFK or completing quests",
      ]
    ),
    new gameNote(
      "Warhammar 40,000: Boltgun",
      2,
      "https://i.imgur.com/gEgEFnm.jpeg",
      "https://www.trueachievements.com/game/Warhammer-40000-Boltgun/achievements",
      "Warhammer-40000-Boltgun",
      false,
      false,
      true,
      false,
      [
        "Has accessibility settings (Invicinble, unlimited ammo) which makes it easy to cheese",
      ]
    ),
    new gameNote(
      "The First Descendant",
      3,
      "https://i.imgur.com/8zPoy2k.jpeg",
      "https://www.trueachievements.com/game/The-First-Descendant/achievements",
      "The-First-Descendant",
      false,
      false,
      false,
      false,
      [
        "Another game with cross platform achievement support that's not tricky but requires around ~70 hours of playtime",
        "Slot Maketh Module is bugged and you have to do all 10 in a single session.  Some people are claiming it's fixed but I didn't risk it",
      ]
    ),
    new gameNote(
      "XDefiant",
      3,
      "https://i.imgur.com/DJ4wNfu.jpeg",
      "https://www.trueachievements.com/game/XDefiant/achievements",
      "XDefiant",
      false,
      false,
      false,
      false,
      [
        "Relatively easy achievements but be aware some are skill based (MVP + 5 killstreak).  Personally I was able to get the completion without boosting anything but apparently it's possible to boost if you are struggling",
        "Features cross platform achievements but completed achievements won't autopop on Xbox instead they get set to 99% if you have them on another platform.  So you have to complete the last 'step' to pop each achievement on Xbox",
      ]
    ),
    new gameNote(
      "Somerville",
      1,
      "https://i.imgur.com/B7LddiR.jpeg",
      "https://www.trueachievements.com/game/Somerville/achievements",
      "Somerville",
      false,
      false,
      false,
      false,
      ["Easy with guide and can be completed in ~2 hours"]
    ),
    new gameNote(
      "Ghostrunner",
      3,
      "https://i.imgur.com/sZfr3i4.jpeg",
      "https://www.trueachievements.com/game/Ghostrunner/achievements",
      "Ghostrunner",
      false,
      false,
      false,
      false,
      [
        "Personally didn't find it too difficult but definitely takes practice too get good at",
        "Using assist mode can cause issues with the collectible achievements so I'd recommend not using it",
      ]
    ),
    new gameNote(
      "Star Wars Episode 1 Racer",
      1,
      "https://i.imgur.com/EUZdoug.jpeg",
      "https://www.trueachievements.com/game/Star-Wars-Episode-I-Racer/achievements",
      "Star-Wars-Episode-1-Racer",
      false,
      false,
      true,
      false,
      ["Can be cheesed using debug menu"]
    ),
    new gameNote(
      "Flintlock: Siege of Dawn",
      3,
      "https://i.imgur.com/t6VnZPe.jpeg",
      "https://www.trueachievements.com/game/Flintlock-The-Siege-of-Dawn/achievements",
      "Flintlock-The-Siege-of-Dawn",
      false,
      false,
      false,
      false,
      [
        "Easy souls-like but you cannot play on the easiest difficulty otherwise it will disable all achievements",
      ]
    ),
    new gameNote(
      "LEGO Builder's Journey",
      2,
      "https://i.imgur.com/7Bw8B7m.jpeg",
      "https://www.trueachievements.com/game/LEGO-Builders-Journey/achievements",
      "LEGO-Builders-Journey",
      false,
      false,
      false,
      false,
      ["No guide needed"]
    ),
    new gameNote(
      "Dungeons of Hinterberg",
      3,
      "https://i.imgur.com/hkkZPJO.jpeg",
      "https://www.trueachievements.com/game/Dungeons-of-Hinterberg/achievements",
      "Dungeons-of-Hinterberg",
      false,
      false,
      false,
      false,
      [
        "Has a few missables but other than that fairly straightforward",
        "No difficulty related achievements so everything can be unlocked on Vacation Mode",
        "New Game+ achievements can all be unlocked in one playthrough",
      ]
    ),
    new gameNote(
      "McPixel 3",
      2,
      "https://i.imgur.com/eQ4z3F3.jpeg",
      "https://www.trueachievements.com/game/Mcpixel-3/achievements",
      "McPixel-3",
      false,
      false,
      false,
      false,
      ["TA walkthrough is good but game can be very tedious"]
    ),
    new gameNote(
      "Shy Cats Hidden Orchestra",
      1,
      "https://i.imgur.com/go6p8Au.jpeg",
      "https://www.trueachievements.com/game/Shy-Cats-Hidden-Orchestra/achievements",
      "Shy-Cats-Hidden-Orchestra",
      false,
      false,
      false,
      false,
      ["Easy with guide", "https://www.youtube.com/watch?v=XXGiHOKbTVc"]
    ),
    new gameNote(
      "Figment 2: Creed Valley",
      2,
      "https://i.imgur.com/vl2FrX1.jpeg",
      "https://www.trueachievements.com/game/Figment-2-Creed-Valley/achievements",
      "Figment-2-Creed-Valley",
      false,
      false,
      false,
      false,
      [
        "Can be completed in a few hours with walkthrough.  Anything missed can be level selected after completing the game",
      ]
    ),
    new gameNote(
      "Alfred Hitchcock - Vertigo",
      2,
      "https://i.imgur.com/JkN4GTp.jpeg",
      "https://www.trueachievements.com/game/Alfred-Hitchcock-Vertigo/achievements",
      "Alfred-Hitchcock-Vertigo",
      false,
      false,
      false,
      false,
      [
        "Just follow linked guide",
        "https://www.youtube.com/watch?v=Wk4yzFniIfI",
      ]
    ),
    new gameNote(
      "Mineko's Night Market",
      3,
      "https://i.imgur.com/3mFFcko.jpeg",
      "https://www.trueachievements.com/game/Minekos-Night-Market/achievements",
      "Minekos-Night-Market",
      false,
      false,
      false,
      false,
      [
        "Pretty grindy and had to deal with quite a few bugs.  Can't recommend but it does have a nice artstyle",
      ]
    ),
    new gameNote(
      "Deliver Us The Moon",
      2,
      "https://i.imgur.com/uQiBJJf.jpeg",
      "https://www.trueachievements.com/game/Deliver-Us-The-Moon/achievements",
      "Deliver-Us-The-Moon",
      false,
      false,
      false,
      false,
      [
        "Can be completed in a few hours with walkthrough.  Anything missed can be level selected after completing the game",
      ]
    ),
    new gameNote(
      "Torchlight",
      2,
      "https://i.imgur.com/AZbENmo.jpeg",
      "https://www.trueachievements.com/game/Torchlight/achievements",
      "Torchlight",
      false,
      false,
      false,
      false,
      [
        "Pretty straightforward but just make sure to go for the 'Adventurer' talent and max it out to 10.  This increases the amount of fame earned which will help speed up the grind to reach max level fame",
      ]
    ),
    new gameNote(
      "Chants of Sennaar",
      2,
      "http://store-images.s-microsoft.com/image/apps.43602.14019132985152487.acce0d33-e53b-464e-94c1-c4bba3e2edbd.9599f7b5-9eda-4bff-897a-1628af364bc9",
      "https://www.trueachievements.com/game/Chants-of-Sennaar/achievements",
      "Chants-of-Sennaar",
      false,
      false,
      false,
      false,
      [
        "Just use a walkthrough if you wanna knock it out in roughly 3 hours but a great puzzle game regardless",
      ]
    ),
    new gameNote(
      "Diablo IV",
      4,
      "http://store-images.s-microsoft.com/image/apps.6241.13768370188591668.488c0279-e238-403d-98d2-67b1088e2d29.80b31eed-dcc4-41d2-af3a-c4aee089c91d",
      "https://www.trueachievements.com/game/Diablo-IV/achievements",
      "Diablo-IV",
      false,
      false,
      false,
      false,
      [
        "Nearly everything can be knocked out when going for a level 100 character with a few exceptions",
        "Don't worry about class specific achievements until you complete the campaign once as you will gain the option to completely skip it for new characters.  This makes the class specific achievements much faster because you can skip the prologue and just jump right in",
        "You can do the PvP achievement locally with two different accounts that have their own battle.net accounts",
        "The Uber Lilith kill is a pretty absurd fight but many different people offer carries.  The diablo subreddit (r/diablo) has a bunch of posts of people that offer carries",
      ]
    ),
    new gameNote(
      "Star Trek: Resurgence",
      2,
      "http://store-images.s-microsoft.com/image/apps.38383.14493522474643266.61f5547b-c3b9-40fe-9f04-af83e2ffc6b1.806c237f-6734-4425-9e0c-1bcf7731e449",
      "https://www.trueachievements.com/game/Star-Trek-Resurgence/achievements",
      "Star-Trek-Resurgence",
      false,
      false,
      false,
      false,
      [
        "Will require two playthroughs but 29/30 achievements can be earned in one playthrough",
        "Just follow guide below",
        "https://www.youtube.com/watch?v=wHxU93_PnQ8",
      ]
    ),
    new gameNote(
      "Valiant Hearts: The Great War",
      2,
      "https://i.imgur.com/MahziIO.png",
      "https://www.trueachievements.com/game/Valiant-Hearts-The-Great-War/achievements",
      "Valiant-Hearts-The-Great-War",
      false,
      false,
      false,
      false,
      [
        "Pretty straightforward but will require a guide for the collectibles",
        "Anything missed can be cleaned up using level select",
      ]
    ),
    new gameNote(
      "Little Kitty, Big City",
      2,
      "http://store-images.s-microsoft.com/image/apps.64698.13605545843678606.ba639605-7305-422e-9637-2411fb07e5f6.5d284d89-4bb5-4098-87e7-0df89bf04b85",
      "https://www.trueachievements.com/game/Little-Kitty-Big-City/achievements",
      "Little-Kitty-Big-City",
      false,
      false,
      false,
      false,
      ["Can be completed almost entirely blind.  Use TA solutions for cleanup"]
    ),
    new gameNote(
      "Bionicle Heroes",
      2,
      "https://i.imgur.com/R2M00Nn.png",
      "https://www.trueachievements.com/game/Bionicle-Heroes/achievements",
      "Bionicle-Heroes",
      false,
      false,
      false,
      false,
      [
        "Similar to most lego games where you can just do one initial playthrough then do a second playthrough with all the special abilities unlocked plus Vezon to get all canisters and golds on each level",
        "I found that replaying the first secret level over and over with a maxed out Nupara was the fastest way to earn lego pieces since you can get 50k in two minutes just replaying the level over and over",
        "Some people on TA have noted issues with corrupted save files for this game.  Personally I never encountered this but did regularly make backup copies of my save just incase",
      ]
    ),
    new gameNote(
      "Persona 3: Reload",
      3,
      "http://store-images.s-microsoft.com/image/apps.59768.13660021874166335.cf99aaa0-1039-41d3-bafe-7239ec9e261c.219dd9bc-69b7-4f09-b013-d5e3092cf821",
      "https://www.trueachievements.com/game/Persona-3-Reload/achievements",
      "Persona-3-Reload",
      false,
      false,
      false,
      false,
      [
        "Not very difficult to complete in one playthrough however the one achievement you need to be constantly aware of is maxing all social links.  There isn't a ton of room for error and messing it up will require either a half or full playthrough as the game has a hard stop on 1/31",
        "Other missables are easy to get just refer to TA solutions before hand so you know when to get them",
        "No difficulty related achievements so you can play it on Peaceful",
        "Some other important notes about maxing social links:",
        "Do not waste any free Sunday and always focus on either the Hermit or Akinari Kamiki since Sunday is the only day these two are available.  Akinari Kamiki has some prerequisites before he will appear so also start on those as quickly as possible",
        "During the school week focus on classmates social link's and avoid social links that meet outside of school.  During vacations, exam week's etc. you will only have access to social links outside of school so save those days for those links",
        "You will occasionally have events options (blue exclaimation mark) with certain characters that lack a social link (Junpei, Sanada, Koromaru etc.) unforuntately these will eat into your potential opportunities to advance social links so make sure you don't waste an afternoon that could be used otherwise",
        "You will need to reach max rank in all three Social Stats to unlock Mitsuru, Fuuka and Yukari's social links.  Courage and Charm can be leveled up early on at the cafe in the mall while Academics can be done either in your bedroom or participating in the study groups in the dorm near exams",
        "Some social links will not become available until the final few months of the game (Mitsuru and Aigis) so make sure to plan ahead all the way to the end",
        "Even with a fairly optimized run for max social links it will probably feel like you aren't going to make it once you reach December.  To pull you over the finish line you need to take advantage of 'Dating Site Note' that the Suspicious Man in Club Escapade sells.  He will start selling it in December and it operates similar to the fortune shrine (slightly increase social link score without having to talk with that person) but allows you to use it at night unlike the shrine which is restricted to the day only. An important note is that you cannot level up a social link using the shrine or dating site notes but it will help avoid the 'wasted' interactions where you won't have a enough social link score to level up during an interaction.  This helps ensure that every time you talk to someone their social link level goes up",
        "Certain answers when you hang out with a social link will boost the social link faster.  Additionally holding a persona that corresponds to the social link you are hanging out with will also give you a bonus.  I would recommend always having a corresponding persona for whatever social link you are working on and always use the optimal answers for max social link score",
        "Below is a useful guide for the correct answers to max all of your interactions",
        "https://www.ign.com/wikis/persona-3-reload/Characters",
      ]
    ),
    new gameNote(
      "DEATHLOOP",
      3,
      "http://store-images.s-microsoft.com/image/apps.5858.14634955238674857.649b7ff9-0dfc-4951-9b65-c5d815215da6.90208516-ba3b-47a9-a130-ef94cf860f5b",
      "https://www.trueachievements.com/game/DEATHLOOP/achievements",
      "DEATHLOOP",
      false,
      false,
      true,
      false,
      [
        "Has accessibility settings (Unlimited revives, one shot kills, target multiple enemies)",
        "This game use to have a ton of issues with achievements not popping but this has since been fixed.  Don't be alarmed if you see TA solutions where tons of people are commenting that achievements aren't popping.  I didn't have any issues and others have confirmed they are no longer having issues",
      ]
    ),
    new gameNote(
      "The Elder Scrolls V: Skyrim Special Edition (Windows)",
      2,
      "http://store-images.s-microsoft.com/image/apps.4794.14468615562328340.fdd2a6d3-ed01-4e8e-805a-630a8e7dcd61.86ec042a-e701-477f-a9a3-3b041045512b",
      "https://www.trueachievements.com/game/The-Elder-Scrolls-V-Skyrim-Special-Edition-Windows/achievements",
      "The-Elder-Scrolls-V-Skyrim-Special-Edition-Windows",
      false,
      false,
      false,
      true,
      [
        "Finish Unbound legit, make a save then use the following console commands to knock out about 50% of the achievements",
        "https://www.trueachievements.com/forum/viewthread.aspx?tid=1269333&anchor=11128600#m11128600",
        "Finally use individual TA solutions for cleanup",
      ]
    ),
    new gameNote(
      "The Raven: Remastered",
      2,
      "http://store-images.s-microsoft.com/image/apps.9659.66775512685532779.9b3184fb-96e0-427b-b828-ee5fb9052c8b.68829784-5eb2-4e1e-b054-e0dbf8fecf62",
      "https://www.trueachievements.com/game/The-Raven-Remastered/achievements",
      "The-Raven-Remastered",
      false,
      false,
      false,
      false,
      [
        "Do not press X at all throughout the game.  Doing so reduces your total score which will lock you out of unlock the 100% completion for each level",
        "Great video walkthrough",
        "https://www.youtube.com/watch?v=1msV-atjPIc",
      ]
    ),
    new gameNote(
      "NORCO",
      2,
      "http://store-images.s-microsoft.com/image/apps.30139.14481237140487412.88b201c5-42cf-43cc-af65-86f48324703c.9c1bd7bb-4697-4cc3-8aaf-262ed2f04189",
      "https://www.trueachievements.com/game/NORCO/achievements",
      "NORCO",
      false,
      false,
      true,
      false,
      [
        "Has accessibility options which don't disable achievements.  These allow you to skip QTE events and grants you the ability to level select",
        "Great written guide",
        "https://www.neoseeker.com/norco/walkthrough/Act_One_-_Lowland_Ghosts",
      ]
    ),
    new gameNote(
      "ARK: Survival Evolved",
      1,
      "http://store-images.s-microsoft.com/image/apps.52635.13510798887668935.4d491123-4e8b-4a93-a019-1d7b498162f8.1832d4d5-fefe-41f8-b123-ea9e193d14b9",
      "https://www.trueachievements.com/game/ARK-Survival-Evolved/achievements",
      "ARK-Survival-Evolved/",
      false,
      false,
      false,
      true,
      ["Can be completed using console commands"]
    ),
    new gameNote(
      "Zeno Clash: Ultimate Edition",
      2,
      "https://i.imgur.com/zBB8mf6.png",
      "https://www.trueachievements.com/game/Zeno-Clash/achievements",
      "Zeno-Clash",
      false,
      false,
      false,
      false,
      [
        "Very weird game with first person beat 'em up gameplay.  TA walkthrough is very helpful and can carry you through",
        "Highly recommend using the heavy punch (LT) attack while strafing forwards and backwards.  Makes it very hard for the enemies to block you",
      ]
    ),
    new gameNote(
      "Botany Manor",
      1,
      "http://store-images.s-microsoft.com/image/apps.41526.14313562065097595.e2df373c-7c5d-4185-9834-210dcde167fd.3f955fb9-a5c4-40a6-ad3b-a1d845f4bcfc",
      "https://www.trueachievements.com/game/Botany-Manor/achievements",
      "Botany-Manor",
      false,
      false,
      false,
      false,
      [
        "Easy with a walkthrough.  No missables so everything can be cleaned up after you complete the game",
      ]
    ),
    new gameNote(
      "RiME",
      2,
      "http://store-images.s-microsoft.com/image/apps.15320.69897438766761437.79976aa1-405e-426e-ba65-066522206a5b.499e4291-e5c2-41e4-aa63-08a309c63638",
      "https://www.trueachievements.com/game/RiME/achievements",
      "RiME",
      false,
      false,
      false,
      false,
      [
        "Doesn't have missables but since the levels take anywhere from 30 minutes to an hour having to level select to cleanup certain achievements can be a pain",
        "Recommend just following the TA walkthrough to knock everything out in one playthrough",
      ]
    ),
    new gameNote(
      "Lightyear Frontier",
      2,
      "http://store-images.s-microsoft.com/image/apps.32170.13610342535970070.70bc8d1f-a634-410d-bc3a-32ab84987744.d02d600e-cee6-4b66-9114-aa2b536bd1bd",
      "https://www.trueachievements.com/game/Lightyear-Frontier/achievements",
      "Lightyear-Frontier",
      false,
      false,
      false,
      false,
      [
        "Straightforward management / farming sim with simple progression mechanics",
        "Has one easy achievement that requires a second player joining an hosted online game",
      ]
    ),
    new gameNote(
      "ABZU",
      1,
      "http://store-images.s-microsoft.com/image/apps.61042.68322781636760871.425ecab7-eee7-4a99-b2e8-1a4916207866.6499733e-2314-4ea5-881e-5b4501e6251f",
      "https://www.trueachievements.com/game/ABZU/achievements",
      "ABZU",
      false,
      false,
      false,
      false,
      ["Easy just follow guide", "https://www.youtube.com/watch?v=HPtjTPbs7SM"]
    ),
    new gameNote(
      "Girl Fight",
      1,
      "http://store-images.s-microsoft.com/image/apps.57660.64341754196844739.a9a5945a-1a60-4917-a4ab-0459a21a0ce4.3ceeacd7-6794-4fbc-b8ae-6e4edc4925c3",
      "https://www.trueachievements.com/game/Girl-Fight/achievements",
      "Girl-Fight",
      false,
      false,
      false,
      false,
      [
        "Easy only thing to be aware of is that it has one online achievement that can be boosted in about five minutes",
      ]
    ),
    new gameNote(
      "Foreclosed",
      2,
      "http://store-images.s-microsoft.com/image/apps.47149.14083188972462344.ca7cd5ec-ae83-49a4-98b1-03c325d6a48b.c78629a2-69bc-47ad-8f78-b044cdb56c3a",
      "https://www.trueachievements.com/game/Foreclosed/achievements",
      "Foreclosed",
      false,
      false,
      false,
      false,
      [
        "Majority of the achievements can be unlocked just playing the story",
        "All other achievements are related to getting X number of kills using certain abilities or weapons.  These can be farmed via chapter select after you finish the game",
        "You can farm headshots by shooting the heads of dead enemies",
        "You can lift the same enemy over and over and will still get credit each time",
        "Good walkthrough if you're stuck",
        "https://www.youtube.com/watch?v=SXcEDaOcEv0",
      ]
    ),
    new gameNote(
      "Open Season",
      2,
      "https://i.imgur.com/CRmhLja.png",
      "https://www.trueachievements.com/game/Open-Season/achievements",
      "Open-Season",
      false,
      false,
      false,
      false,
      [
        "TA walkthrough isn't very good.  Use the individual solutions to get 100% on each level instead",
      ]
    ),
    new gameNote(
      "XIII",
      2,
      "http://store-images.s-microsoft.com/image/apps.55335.14349333331222673.ad1e9f5b-e493-405e-8181-7a8081f3ee61.f0ca901a-82ff-4268-b8e6-a9497f51b9a2",
      "https://www.trueachievements.com/game/XIII/achievements",
      "XIII",
      false,
      false,
      false,
      false,
      [
        "You can complete the game on the easiest difficulty then just level select the last level on the hardest difficulty and complete it (it has no combat) to pop all the difficulty related achievements",
        "Useful walkthrough if you get stuck on any levels",
        "https://www.youtube.com/watch?v=JbnhGpOst3c",
      ]
    ),
    new gameNote(
      "Rise of the Guardians",
      1,
      "https://i.imgur.com/QxtfiEI.png",
      "https://www.trueachievements.com/game/Rise-of-the-Guardians/achievements",
      "Rise-of-the-Guardians",
      false,
      false,
      false,
      false,
      [
        "Can be completed almost entirely blind since all collectibles appear on the map by default.  Use TA solutions for any misc cleanup at the end",
      ]
    ),
    new gameNote(
      "Roboquest",
      4,
      "http://store-images.s-microsoft.com/image/apps.4681.14593591481307987.72e4b0a6-f3d5-43bf-bcbf-7412b16dca48.7a62f094-7b6d-499f-b2b1-99ba3cf11e5b",
      "https://www.trueachievements.com/game/Roboquest/achievements",
      "Roboquest",
      false,
      false,
      false,
      false,
      [
        "Only two difficulty achievements so you can just put the game on 'Discovery' and focus on unlocking all characters and permanent upgrades if you want",
        "Has a few co-op achievements however if your co-op partner disconnects the co-op session will still be active as long as you don't quit so you can keep playing solo and the game will still be treated as a co-op game",
        "A word of warning, adjusting to the harder difficulties will take some time and practice as you will need to be constantly moving and jumping to stay alive.  Personally I had a lot of success with the Cryo Engineer build recommended on TA while going for heavy weapons (Junk Beam, Volcanic Cannon primarily).  Personally I dropped the buddybot and went foxgun (upgrade to fantastic in the oasis) then constantly marked enemies and bosses for the damage boost",
        "For Guardian 3 and 4 enabling the sunglasses is basically required as you simply won't do enough damage to the bosses",
      ]
    ),
    new gameNote(
      "The Sojourn",
      2,
      "http://store-images.s-microsoft.com/image/apps.25408.14083387727962531.4a5c677f-b465-4c8d-bd0a-9755270f5e2a.c8d51c01-b3c1-4fc9-a80d-b96094995a37",
      "https://www.trueachievements.com/game/The-Sojourn/achievements",
      "The-Sojourn",
      false,
      false,
      false,
      false,
      [
        "Decent puzzle game.  You can use the walkthrough below for any levels you have trouble with",
        "https://www.youtube.com/watch?v=Iy7F_NcVBtI",
      ]
    ),
    new gameNote(
      "Hell Yeah! Wraith of the Dead Rabbit",
      2,
      "https://i.imgur.com/rTnvcwz.png",
      "https://www.trueachievements.com/game/Hell-Yeah-Wrath-of-the-Dead-Rabbit/achievements",
      "Hell-Yeah-Wrath-of-the-Dead-Rabbit",
      true,
      false,
      false,
      false,
      [
        "Terrible game for many reasons and for 400G's it's a waste of time.  If you do want to suffer through it for some reason this walkthrough was helpful",
        "https://www.youtube.com/watch?v=VJ7pMWKmJvE",
      ]
    ),
    new gameNote(
      "Close to the Sun",
      2,
      "http://store-images.s-microsoft.com/image/apps.22388.14023175679911698.7f246870-ac74-421d-83b6-d932c336a571.4bbc2c0c-f53c-4f80-b87d-ef82f507cca6",
      "https://www.trueachievements.com/game/Close-to-the-Sun/achievements",
      "Close-to-the-Sun",
      false,
      false,
      false,
      true,
      [
        "Console commands can be used on Xbox if you plug in a keyboard to pop endgame achievements",
        "After that you can just follow a guide to get all collectibles to cleanup remaining achievements",
      ]
    ),
    new gameNote(
      "Rollerdrome",
      2,
      "http://store-images.s-microsoft.com/image/apps.12037.14238209188187584.16998e2b-4b4b-441e-b9f8-cd3265cedfd9.e40fa62a-4a40-4343-915d-ef8422069d8f",
      "https://www.trueachievements.com/game/Rollerdrome/achievements",
      "Rollerdrome",
      false,
      false,
      true,
      false,
      [
        "Has accessibility settings that don't affect achievements which can make gameplay much easier",
        "There is a bug using the grenade launcher to hold an infinite combo.  Trivializes the point challenges",
      ]
    ),
    new gameNote(
      "Minecraft: Legends",
      3,
      "http://store-images.s-microsoft.com/image/apps.62581.13541118697769822.c231b43e-06a3-412e-91d8-537c53fc0a06.80cde93c-b8eb-478f-85d0-afecd16ebcd2",
      "https://www.trueachievements.com/game/Minecraft-Legends/achievements",
      "Minecraft-Legends",
      false,
      false,
      false,
      false,
      [
        "Many achievements can be boosted in custom campaign games",
        "I would highly recommend doing the legendary campaign with other people in order to make the process easier.  Legendary can be extreme tedious solo",
      ]
    ),
    new gameNote(
      "Men in Black: Alien Crisis",
      2,
      null,
      "https://www.trueachievements.com/game/Men-In-Black-Alien-Crisis/achievements",
      "Men-In-Black-Alien-Crisis",
      false,
      false,
      false,
      false,
      [
        "Just follow TA walkthrough and chapter select any missed achievements",
        "Requires second controller for the VR missions achievements",
      ]
    ),
    new gameNote(
      "The LEGO NINJAGO Movie Video Game",
      2,
      "http://store-images.s-microsoft.com/image/apps.43970.69139473148334582.b7290ab6-508c-479e-8827-a21822bbed42.af0bdf3f-98e6-407c-91a1-910ee0c18a93",
      "https://www.trueachievements.com/game/The-LEGO-NINJAGO-Movie-Video-Game/achievements",
      "The-LEGO-NINJAGO-Movie-Video-Game",
      false,
      false,
      false,
      false,
      [
        "Standard lego game but this one isn't as crazy with collectables so completion time isn't crazy.  Only recommendation is completing the story before doing anything extra as you will unlock permanent upgrades from completing story missions that make the rest of the game easier",
        "A number of people in the comments of solutions on TA are stating that certain achievements won't pop after using in-game codes to unlock characters.  Personally recommend just getting everything legit without using the in-game codes to avoid any issues",
      ]
    ),
    new gameNote(
      "TOEM",
      2,
      "http://store-images.s-microsoft.com/image/apps.53548.14059948614171780.9cc4eb83-d9f3-4756-9b6c-b04da030506d.79321886-3656-47d5-8074-268b2bfb447a",
      "https://www.trueachievements.com/game/TOEM/achievements",
      "TOEM",
      false,
      false,
      false,
      false,
      [
        "No missables and majority of the game can be completed blind",
        "Great 100% walkthrough on Steam that can be used for cleanup",
        "https://steamcommunity.com/sharedfiles/filedetails/?id=2606157063",
      ]
    ),
    new gameNote(
      "Tunic",
      2,
      "http://store-images.s-microsoft.com/image/apps.39322.13702044937897358.8701ab78-da18-4c36-8c1e-3d4e6dfd60c9.a0be6178-25eb-43e3-9a52-170445da58fc",
      "https://www.trueachievements.com/game/Tunic/achievements",
      "Tunic",
      false,
      false,
      true,
      false,
      [
        "Has accessibility settings that don't affect achievements which can make gameplay much easier",
        "This game will not explain how to do things and you will instead need to rely heavily on the digital instruction manual to figure things out",
      ]
    ),
    new gameNote(
      "#IDARB",
      1,
      "http://store-images.s-microsoft.com/image/apps.3347.13510798885955481.62222435-c470-422e-8917-a258d918faa9.d434cb5b-4bdb-448b-9ad5-d7c4ad6d3ced",
      "https://www.trueachievements.com/game/IDARB/achievements",
      "IDARB",
      false,
      false,
      false,
      false,
      [
        "Follow TA walkthrough",
        "Has one achievement that requires you to be in an online game with at least two people which can easily be boosted but can't be done locally",
      ]
    ),
    new gameNote(
      "Dead Space: Ignition",
      2,
      "http://store-images.s-microsoft.com/image/apps.1218.66616941329633673.ed23a5d1-2731-4da6-a036-6dcecee38b30.26f58588-9bd2-4d3a-9ff4-445d570109ad",
      "https://www.trueachievements.com/game/Dead-Space-Ignition/achievements",
      "Dead-Space-Ignition",
      false,
      false,
      false,
      false,
      [
        "Just follow the TA walkthrough since it outlines the different paths required to 100% everything",
        "Can be completed in ~3.75 playthroughs taking about 25 to 30 minutes for each run",
        "Make sure one of your playthroughs is using the leaderboards for 'Catch me if you can!'",
      ]
    ),
    new gameNote(
      "The Wolf Among Us",
      1,
      "http://store-images.s-microsoft.com/image/apps.27671.64565521137234771.d4fa27af-3a00-44af-9927-ce57a7066702.18cb9cae-df7a-4c82-829f-75e83d28b4e4",
      "https://www.trueachievements.com/game/The-Wolf-Among-Us-Xbox-360/achievements",
      "The-Wolf-Among-Us",
      false,
      false,
      false,
      false,
      ["Refer to walkthrough on which fable entries might be missed"]
    ),
    new gameNote(
      "Total Arcade Racing",
      2,
      "http://store-images.s-microsoft.com/image/apps.41450.14525588192841666.cefddc2d-e0e8-425e-83fa-317506aeea91.061de691-609d-4517-ad64-2f8265e56634",
      "https://www.trueachievements.com/game/Total-Arcade-Racing/achievements",
      "Total-Arcade-Racing",
      false,
      false,
      false,
      false,
      [
        "Topdown arcade racer which requires some getting use to",
        "The championship achievement can be boosted locally on PC using four xbox controllers plus the keyboard which can count as up to four players on it's own.  From there you can just drive without having to worry about trying to beat the AI",
        "The 100 miles driven is the only thing that is slight time consuming and should be saved for last",
      ]
    ),
    new gameNote(
      "Super Lucky's Tale",
      3,
      "http://store-images.s-microsoft.com/image/apps.18265.14315077775214047.0aff2cf3-0ff7-4629-8ad6-722a2d8dac54.8bbbd953-eac8-44fb-ac0f-b3abffb2a7c4",
      "https://www.trueachievements.com/game/Super-Luckys-Tale/achievements",
      "Super-Luckys-Tale",
      false,
      false,
      false,
      false,
      [
        "Basically the same game as the 'new' version but with some additional achievements that are very annoying",
        "The 99,999 coin achievement is a huge grind and will probably take up at least a third to half of your total playtime",
      ]
    ),
    new gameNote(
      "Brotato",
      3,
      "http://store-images.s-microsoft.com/image/apps.34767.14130976257579640.bd7a2231-2a21-4516-807a-46a50286744a.614cc94b-88ac-4943-bbbd-b73979590c69",
      "https://www.trueachievements.com/game/Brotato/achievements",
      "Brotato",
      false,
      false,
      true,
      false,
      [
        "Has accessibility settings that don't affect achievements which can make gameplay much easier",
        "You will need to complete a run with every character so at minimum you will need to do 44 complete runs which isn't exactly quick by any means",
        "Just as a heads up this game has a ton of odd points achievements",
      ]
    ),
    new gameNote(
      "Warhammer 40,000: Darktide",
      4,
      "http://store-images.s-microsoft.com/image/apps.48074.14421726920549205.4ed1a8ed-1549-4013-8769-b57c45ca99b5.daa0830c-a776-4941-80a0-dae56f440920",
      "https://www.trueachievements.com/game/Warhammer-40000-Darktide/achievements",
      "Warhammer-40000-Darktide",
      false,
      false,
      false,
      false,
      [
        "Since all achievements are online coop the game will only become more difficult as the player base decreases unless you have a dedicated group",
        "Many achievements are actually easier to unlock on harder difficulties because there are larger amounts of enemies",
      ]
    ),
    new gameNote(
      "Turnip Boy Robs a Bank",
      2,
      "http://store-images.s-microsoft.com/image/apps.61861.13607962435826086.cd471fe6-3dd8-4df9-a01c-9b94312c7c3b.a2e6940c-8bb9-4485-a593-392e842e9469",
      "https://www.trueachievements.com/game/Turnip-Boy-Robs-a-Bank/achievements",
      "Turnip-Boy-Robs-a-Bank",
      false,
      false,
      true,
      false,
      [
        "Has accessibility settings that don't affect achievements which can make gameplay much easier",
        "Different than the previous game since it's a roguelite so there is more randomness to progressing quests since certain rooms are randomized each run",
      ]
    ),
    new gameNote(
      "Max: The Curse of Brotherhood",
      2,
      "http://store-images.s-microsoft.com/image/apps.34255.64425461392281454.879d20d4-a065-415c-8776-8483672ad5d1.25a30b51-1813-46f5-bc8c-610c11cdc021",
      "https://www.trueachievements.com/game/Max-The-Curse-of-Brotherhood/achievements",
      "Max-The-Curse-of-Brotherhood",
      false,
      false,
      false,
      false,
      [
        "Will require a walkthrough for the collectibles but they can be level selected if missed and each level has a collectible tracker",
        "Level's that have a 'Complete without dying' achievement can be cheesed by backing out to the main menu then using the 'Continue' option.  Continuing from an autosave via the main menu will reset the game's death counter",
      ]
    ),
    new gameNote(
      "Madden NFL 08",
      1,
      "https://i.imgur.com/B6Rk9Za.png",
      "https://www.trueachievements.com/game/Madden-NFL-08/achievements",
      "Madden-NFL-08",
      false,
      false,
      false,
      false,
      [
        "Sim basically everything via SuperSim option in the pause menu and set opponent to 0 and you to 100 and play out to get most the cleanup after simming games stops popping achievements",
        "You can pause the game and go to game stats to check your player stats. This is useful for seeing if you've met the requirements for a number of the achievements since many achievements won't pop till the end of the game",
        "For Recover 2 Fumbles - do Pats VS Texans at 40F, Overcast, Heavy, Moderate wind, 15 minutes, All-Madden and keep simming the entire game.  Just repeat the process until you get it",
        "For 200 Kick Return Yards - After returning the kickoff, when you get the ball run it back to your 5 yard line then run out of bounds, then kick a field goal (Special -> Special Teams -> Field Goal) - this will automatically turn over the ball.  Then the other team should either score or kick a field goal on 4th down and then they'll do a kickoff.  Run for as much yardage as possible then rinse and repeat",
      ]
    ),
    new gameNote(
      "Mirror's Edge: Catalyst",
      3,
      "http://store-images.s-microsoft.com/image/apps.62830.66522486697599341.54595999-7800-48a6-8255-bf581c71e0dd.fdab5a16-85bd-4fa0-9c88-8ec2f16ced89",
      "https://www.trueachievements.com/game/Mirrors-Edge-Catalyst/achievements",
      "Mirrors-Edge-Catalyst",
      false,
      true,
      false,
      false,
      [
        "The two online achievements are discontinued as of Dec 2023 and the game can no longer be completed 100%.  EA has a history of servers occasionally coming back online so might be a chance in the future but don't count on it.  Thankfully the two achievements are super easy so on the off chance they do you can do them extremely quickly",
        "There are no collectible achievements related for electronic parts only for recordings, documents and bags",
        "Completing some of the deliveries and getting 3 stars on some of the dashes definitely require practice and I would highly recommend watching YouTube videos right away if you get stuck.  Some have really silly and obscure shortcuts so better to not waste time and just check YouTube unless you are very close to getting the 3 stars on blind attempts",
        "Campaign related collectibles won't count if you die before reaching a checkpoint (open world ones save immediately).  Additionally if don't complete said mission to the end (You select 'Abort Mission' or quit the game) none of collected campaign related collectibles that you picked up will save",
      ]
    ),
    new gameNote(
      "Arkadian Warriors",
      1,
      "https://i.imgur.com/kr0Y1Eq.png",
      "https://www.trueachievements.com/game/Arkadian-Warriors/achievements",
      "Arkadian-Warriors",
      false,
      false,
      false,
      false,
      [
        "Has one co-op achievement but you can just replay the first quest twice for credit",
        "You can talk to the woman you stand in-front of when you first load into the game to replay levels.  You will need to do this as in some cases you will be under geared and need to farm gold to buy better gear",
      ]
    ),
    new gameNote(
      "Vampire Survivors",
      3,
      "http://store-images.s-microsoft.com/image/apps.57401.14160661575041057.2c4811ab-1f9a-403e-9d3b-601b0ce1905e.1b317d02-7e77-4468-a6e9-29a5ab534b03",
      "https://www.trueachievements.com/game/Vampire-Survivors/achievements",
      "Vampire-Survivors",
      false,
      false,
      false,
      false,
      [
        "Generally pretty easy just grindy due to having hundreds of achievements",
        "Focus first on unlocking all evolved weapons and getting characters which will make it easier for unlocking more difficult achievements",
        "Forbidden Scrolls of Morbane is a in-game cheat system that allows you to unlock items / characters but in some cases you will still need to complete the normal requirements for unlocking said item or character in order to get the achievement",
      ]
    ),
    new gameNote(
      "Prince of Persia: The Forgotten Sands",
      2,
      "http://store-images.s-microsoft.com/image/apps.6314.69055455676545154.bb0c2527-3a89-4cd5-817e-fcda3c97dad7.d9506364-67ab-4916-804c-bf7c30b64ea7",
      "https://www.trueachievements.com/game/Prince-of-Persia-the-Forgotten-Sands/achievements",
      "Prince-of-Persia-the-Forgotten-Sands",
      false,
      false,
      false,
      false,
      [
        "Overall not tricky with walkthrough however there are a few important details to be aware of",
        "Three missables, two tied to killing bosses without taking damage and one for the collectibles.  What makes these a bit tricky is that the game frequently auto-saves meaning that if you aren't careful you'll progress too far and have to restart the entire game",
      ]
    ),
    new gameNote(
      "Warhammer 40,000: Kill Team",
      1,
      "https://i.imgur.com/CAblHl5.png",
      "https://www.trueachievements.com/game/Warhammer-40K-Kill-Team/achievements",
      "Warhammar-40k-Kill-Team",
      false,
      false,
      false,
      false,
      [
        "Requires two full playthroughs.  Has one easy co-op achievement, so you'll need a second controller",
      ]
    ),
    new gameNote(
      "Brothers: A Tale of Two Sons",
      1,
      "https://i.imgur.com/ELMn3ZO.png",
      "https://www.trueachievements.com/game/Brothers-A-Tale-of-Two-Sons-Xbox-360/achievements",
      "Brothers-A-Tale-of-Two-Sons-Xbox-360",
      false,
      false,
      false,
      false,
      [
        "No story based achievements so recommendation is a TA walkthrough",
        "You can chapter select anything missed so no missables",
      ]
    ),
    new gameNote(
      "Deadlight",
      1,
      "https://i.imgur.com/6ZOMYsz.png",
      "https://www.trueachievements.com/game/Deadlight/achievements",
      "Deadlight",
      false,
      false,
      false,
      false,
      [
        "Quite a few collectibles, chapter specific achievements and a viral achievement (Need a someone on your friendslist with the game)",
        "You can chapter select anything missed so no missables",
      ]
    ),
    new gameNote(
      "Escape Academy",
      2,
      "http://store-images.s-microsoft.com/image/apps.24434.14539026038241714.3df5a7c0-8dab-4179-87d8-320dbb169675.8863ffde-6cd7-433d-a36c-ff26eef2de53",
      "https://www.trueachievements.com/game/Escape-Academy/achievements",
      "Escape-Academy",
      false,
      false,
      false,
      false,
      [
        "Some levels have extra achievements tied to completing them in co-op so you'll need a second controller",
        "PvP title update makes the completion much more of a grind now",
      ]
    ),
    new gameNote(
      "Day of the Tentacle Remastered",
      1,
      "http://store-images.s-microsoft.com/image/apps.18669.13659998887820416.ac97c487-e49c-4236-a48f-681c4095040e.27089192-fc55-4fcc-910d-aebc79555211",
      "https://www.trueachievements.com/game/Day-of-the-Tentacle-Remastered/achievements",
      "Day-of-the-Tentacle-Remastered",
      false,
      false,
      false,
      false,
      [
        "Lots of missables to be aware of but overall quite easy with the TA walkthrough",
      ]
    ),
    new gameNote(
      "SEGA Vintage Collection: Streets of Rage",
      2,
      "http://store-images.s-microsoft.com/image/apps.16735.64551636260013700.1f1c7f34-b3c0-4bd6-9f25-d2eaf60e807c.9db99601-b40e-4c57-92b2-21acfb43afab",
      "https://www.trueachievements.com/game/SEGA-Vintage-Collection-Streets-of-Rage/achievements",
      "SEGA-Vintage-Collection",
      false,
      false,
      false,
      false,
      [
        "Not super difficult thanks to the in-game cheats for extra lives and round select",
        "I did the first two games on the NA region and the third on the Japan region to get the 'Syndicate Crusher' achievement to pop because the game prematurely ends on SoR3 if you play NA and won't count as completed",
        "A word of warning regarding the TA walkthrough.  For the first and second game tha TA walkthrough is solid however when it comes to the third game it's just straight up wrong on a number of different things regarding how cheats work and when achievements pop.  Would recommend using the invididual TA solutions for the third game",
      ]
    ),
    new gameNote(
      "ARCADE GAME SERIES: Ms. Pac-Man",
      3,
      "http://store-images.s-microsoft.com/image/apps.16843.63537904032415955.9ceaa0e7-0905-4778-bb17-af69befb48d7.ee475072-98af-4f61-b14b-63e359d33391",
      "https://www.trueachievements.com/game/ARCADE-GAME-SERIES-Ms-PAC-MAN/achievements",
      "ARCADE-GAME-SERIES-Ms-PAC-MAN",
      false,
      false,
      false,
      false,
      [
        "Picky Eater and Perfect require a decent amount of both luck and practice to pull off.  Their ratio's are a bit misleading for how difficult they are to actually unlock",
      ]
    ),
    new gameNote(
      "A Short Hike",
      2,
      "http://store-images.s-microsoft.com/image/apps.22162.13662153058855583.d07f97b6-b2ec-4a4c-9bce-a92d676f427c.50df9115-c771-4c9e-9e29-fd63adf4ab1b",
      "https://www.trueachievements.com/game/A-Short-Hike/achievements",
      "A-Short-Hike",
      false,
      false,
      false,
      false,
      [
        "Refer to TA solutions for collectible cleanup but no missables so can just be played blind",
        "The boat achievement is a bit annoying at first because of the controls but very doable",
      ]
    ),
    new gameNote(
      "Starfield",
      3,
      "http://store-images.s-microsoft.com/image/apps.1647.13567343664224659.1eb6fdf9-8a0b-4344-a135-ab17dfa3c609.39331391-9de2-48f8-8643-8eb1bd964383",
      "https://www.trueachievements.com/game/Starfield/achievements",
      "Starfield",
      false,
      false,
      false,
      false,
      [
        "No difficulty related achievements so you can play on the easiest difficulty",
        "Complete the Freestar Collective questline early on as its completion rewards you with a powerful ship with 2k cargo capacity which makes dealing with a lot of space fights and resource hordering a breeze",
        "Leave the level 50 and 100 achievements for the very end as you will earn a ton of XP from just doing miscellaneous cleanup.  An important note is the current fastest XP farming method shared on TA takes some setup and a few skill points in specific perks slots so start planning ahead a bit once you are nearing the completion",
      ]
    ),
    new gameNote(
      "Hardspace: Shipbreaker",
      3,
      "http://store-images.s-microsoft.com/image/apps.11359.13847805030494880.d28ba4ad-dbbf-454b-b554-538bad405744.8d315445-ee57-43e8-9e95-4416a34c4f3a",
      "https://www.trueachievements.com/game/Hardspace-Shipbreaker/achievements",
      "Hardspace-Shipbreaker",
      false,
      false,
      false,
      false,
      [
        "Grindy after a while but easy once you understand how to dismantle each ship type",
        "There are only two missables in the game, both being in Act 3, and both can be earned in one playthrough (Pick one choice quit out without saving then reload and pick the other choice).  Refer to the TA Solution for 'Solidarity Forever' or 'Disagree and Commit' to get overview because messing this up will require a new playthrough of about ~80% of the game again",
        "No difficulty achievements and no achievements are negated by playing on Open Shift + Oxygen Drain Disabled",
        "All DLC achievements are part of the base game and can be unlocked through normal progression",
        "TA estimate is 50-60h which is pretty excessive.  Finished in half the time and wasn't even trying to speedrun or go fast",
      ]
    ),
    new gameNote(
      "Frog Detective: The Entire Mystery",
      1,
      "http://store-images.s-microsoft.com/image/apps.13877.13922992293403127.db53b854-7ad3-4927-b3c0-bf615d8f5828.35ef2af6-f248-4018-bdd2-b9c6eca8f60b",
      "https://www.trueachievements.com/game/Frog-Detective-The-Entire-Mystery/achievements",
      "Frog-Detective-The-Entire-Mystery",
      false,
      false,
      false,
      false,
      [
        "Can be completed under two hours with a walkthrough which takes advantage of the scene select menu to skip large chunks of each game",
      ]
    ),
    new gameNote(
      "Bleeding Edge",
      2,
      "http://store-images.s-microsoft.com/image/apps.10811.14431087147384961.13dc1a18-3532-477b-b2d2-772256efc040.87d93a78-9f25-423f-86e7-694f51d12e03",
      "https://www.trueachievements.com/game/Bleeding-Edge/achievements",
      "Bleeding-Edge",
      true,
      false,
      false,
      false,
      [
        "The achievements themselves aren't very hard and can mostly be earned by just playing the game however due to the completely dead player base actually finding a game is a huge pain unless you have a group of people to boost with.  The most annoying aspect of matchmaking is that a match will ONLY start if you have eight players connected - so no bots or partial matches where half the lobby is filled",
        "If you are going for the completion, I would just spam Kulev since he can do both damage and healing to get those cumulative achievements",
      ]
    ),
    new gameNote(
      "Far Cry 3 Blood Dragon Classic Edition",
      2,
      "http://store-images.s-microsoft.com/image/apps.59868.14398170082788055.39f3a948-ce24-48db-9c27-8712bd291d87.a623bc5e-e37f-41d8-9d17-e961d2e3e7d1",
      "https://www.trueachievements.com/game/Far-Cry-3-Blood-Dragon-Classic-Edition/achievements",
      "Far-Cry-3-Blood-Dragon-Classic-Edition",
      false,
      false,
      false,
      false,
      [
        "Identical to the 360 stack with a slightly different name",
        "Has one missable but as long as you try to headshot every enemy type from the start it shouldn't be difficult to get.  Other than that everything can be cleaned up after completing the campaign",
      ]
    ),
    new gameNote(
      "Jusant",
      2,
      "http://store-images.s-microsoft.com/image/apps.54677.14215108845219593.24efa074-5329-4fb7-9ee7-875398d40686.d86ecb73-9324-42b2-8023-dcec8c4783b8",
      "https://www.trueachievements.com/game/Jusant/achievements",
      "Jusant",
      false,
      false,
      false,
      false,
      [
        "No missables but has over a hundred collectables so best with walkthrough",
        "Completing the game unlocks chapter select with a collectable tracker so you can just replay missions if you missed anything",
      ]
    ),
    new gameNote(
      "Hidden Cats in London",
      1,
      "http://store-images.s-microsoft.com/image/apps.20178.13790031285707241.bf63e25b-c04a-429c-b891-08d853d21155.c3be10db-2b01-48d8-b150-4a269fb0b8ec",
      "https://www.trueachievements.com/game/Hidden-Cats-in-London/achievements",
      "Hidden-Cats-in-London",
      false,
      false,
      false,
      false,
      [
        "Use this guide for everything",
        "https://www.youtube.com/watch?v=yaFXhe9g-C4",
      ]
    ),
    new gameNote(
      "Coffee Talk",
      1,
      "http://store-images.s-microsoft.com/image/apps.39999.13910765112993759.52362bf2-618d-426a-a739-5972299c2d98.b450b32b-9e5a-4c16-90c6-b189cb11f7de",
      "https://www.trueachievements.com/game/Coffee-Talk/achievements",
      "Coffee-Talk",
      false,
      false,
      false,
      false,
      [
        "Just follow TA walkthrough, no missables or anything.  You can just skip the text if you don't care about the story",
      ]
    ),
    new gameNote(
      "Cocoon",
      2,
      "http://store-images.s-microsoft.com/image/apps.30735.13933554322251706.0a9bd242-c253-4ad3-882f-a5699d708be5.259c1ea8-80a0-455a-98eb-ba1219b63577",
      "https://www.trueachievements.com/game/Cocoon/achievements",
      "Cocoon",
      false,
      false,
      false,
      false,
      [
        "Collectables can be chapter selected if missed.  All other achievements are story related",
      ]
    ),
    new gameNote(
      "Risk of Rain",
      2,
      "http://store-images.s-microsoft.com/image/apps.32927.14103139940475989.16a966f0-1e46-4edf-9c49-5abc6f5e6116.f6337326-278f-4288-a0a7-61b1a076bf8e",
      "https://www.trueachievements.com/game/Risk-of-Rain/achievements",
      "Risk-of-Rain",
      true,
      false,
      false,
      false,
      [
        "Not a difficult completion but has a couple of very RNG heavy achievements don't make it worth it in my opinion",
        "Estimated TA completion time is 12-15hrs but it took me ~28hrs",
      ]
    ),
    new gameNote(
      "INSIDE",
      1,
      "http://store-images.s-microsoft.com/image/apps.26010.65290118777571754.27f1da0f-d581-4307-9bec-41016a2e5567.7b9d8bfe-920c-447d-a3f0-fa336b8d077f",
      "https://www.trueachievements.com/game/INSIDE/achievements",
      "INSIDE",
      false,
      false,
      false,
      false,
      [
        "Any achievements missed can be cleaned up with chapter select upon completion of the game",
      ]
    ),
    new gameNote(
      "High on Life",
      3,
      "http://store-images.s-microsoft.com/image/apps.52715.13714596773589453.b0e22fc5-f8b4-4b36-b12d-db5d42554c10.e5dbf670-e6a5-411e-8e49-0a6f37882f87",
      "https://www.trueachievements.com/game/High-on-Life/achievements",
      "High-on-Life",
      false,
      false,
      false,
      false,
      [
        "Mods Please Ban achievement was patched but the game still has it's fair share of bugs.  Nothing that required starting a new save however in my case",
        "Some of the missables are very easy to mess up so be very careful",
      ]
    ),
    new gameNote(
      "SIGNALIS",
      1,
      "http://store-images.s-microsoft.com/image/apps.11569.14318995650611811.0f3dee8c-81a0-462b-ad92-c6b77b6038ef.a4d6d11e-2062-44e6-8172-ab61a4a7ea69",
      "https://www.trueachievements.com/game/SIGNALIS/achievements",
      "SIGNALIS",
      false,
      false,
      false,
      true,
      [
        "PC version can be completed in an hour with console commands",
        "TA walkthrough is suprisingly bad; Use the video walkthrough below instead",
        "https://www.youtube.com/watch?v=o_l7ar69K9A",
      ]
    ),
    new gameNote(
      "Minecraft Dungeons",
      3,
      "http://store-images.s-microsoft.com/image/apps.45275.14045794648370014.2229d39b-90c3-496e-8fac-9987450ca4d8.f422dee5-e9ee-4bfb-8f09-742de22ef8c2",
      "https://www.trueachievements.com/game/Minecraft-Dungeons/achievements",
      "Minecraft-Dungeons",
      false,
      false,
      false,
      false,
      [
        "Very grindy but fairly easy (Took about 100 hours for me to get the completion)",
        "Speedrun related achievements - Two Boots of swiftness + corrupted seeds and a strong bow that can clear out packs super fast.  Get some gear with the enchantment 'Multi-Roll' giving you up to 4 rolls.  This is super useful to keep moving and get you out of a tight spot",
        "Back to Back - Solo until the final two double doors ending the level / ancient hunt.  Plug in local coop controller, sign in locally and end the level to get co-op credit",
        "Ancient Hunter - This achievement can be popped by using a trick where you let pet summon's (bee's, iron golum, llama) deal the killing blow in order for it to register.  Some people have reported issues otherwise and I used this method and had no issues registering the ancient hunt kills",
        "To switch battlepasses, whatever one you are actively looking at when exiting the activity hub will be swapped to active.  There is a well known XP glitch that can help speed up the time it takes to grind out the season pass levels and a number of TA solutions explain how to does this properly",
      ]
    ),
    new gameNote(
      "Bridge Constructor: The Walking Dead",
      2,
      "http://store-images.s-microsoft.com/image/apps.29711.14508979435126955.4b584f4a-6e8c-4284-bad4-0a5290e09ce6.b3fb74c4-37a9-4e44-a97a-a8aa18548f5d",
      "https://www.trueachievements.com/game/Bridge-Constructor-The-Walking-Dead/achievements",
      "Bridge-Constructor-The-Walking-Dead",
      false,
      false,
      false,
      false,
      [
        "TA walkthrough is quite good and walks you through how to complete each level however there's always variance from what the walkthrough shows to how the game will actually play out (which the walkthrough warns you of).  This requires some tweaking and luck on your part to get the solutions to actual pass but personally I never had any major issues",
        "Don't worry about the 400 kills achievement too much.  Easily reached it without really going out of my way and you can just replay levels if you happen to be under the threshold",
      ]
    ),
    new gameNote(
      "DPS Idle",
      1,
      "http://store-images.s-microsoft.com/image/apps.37407.14301552142031019.56b30a4e-e3b5-444d-a7a8-5afb36dee626.dabd0237-d5bc-4521-b562-62d8acc0c974",
      "https://www.trueachievements.com/game/DPS-Idle/achievements",
      "DPS-Idle",
      false,
      false,
      false,
      false,
      [
        "Great walkthrough for getting the quick completion",
        "https://www.youtube.com/watch?v=jm5KSVlNvls",
      ]
    ),
    new gameNote(
      "Firewatch",
      1,
      "http://store-images.s-microsoft.com/image/apps.40478.68688977722932617.c18986e0-354d-49e5-a689-33d52a7432cb.15d49e14-f3c9-4949-b807-62a02521442b",
      "https://www.trueachievements.com/game/Firewatch/achievements",
      "Firewatch",
      false,
      false,
      false,
      false,
      [
        "Any missed achievements can be cleaned up in free roam mode. All other achievements are story related",
      ]
    ),
    new gameNote(
      "Overwhelm",
      2,
      "http://store-images.s-microsoft.com/image/apps.35954.14532258033050756.91273ea3-bea8-454e-bed7-2d67a04c5e65.dc59d5a4-3f79-43dc-a739-a7423d506968",
      "https://www.trueachievements.com/game/Overwhelm/achievements",
      "Overwhelm",
      false,
      false,
      true,
      false,
      [
        "Similar to games like Hotline Miami where you only have one hit life",
        "Enable all assist options in 'options' -> 'difficulty' as they do not disable achievements",
        "It's been reported on TA that people are having their progress erased after starting NG+ then saving and quitting the game.  Recommended to do the whole game in one sitting if possible to avoid this (this is what I did to avoid any issues)",
        "The game features five different bosses which you can defeat in any order.  Regardless of the order you choose to complete them the final boss will always take their ultimate form.  The easiest boss in ultimate form is the bottom left one (bush spider) so saving it for last is recommended",
        "Kraken (Bottom Right) - The 'hardest' boss in the game.  Keep rotating in and out of the water around the platform to the left which is slightly above the water of the crystal.  In the second phase, when the boss charges you, stand above water and drop straight down (not too far down) to avoid it, if you try to move diagonally you won't have enough downward speed to avoid it.  Waiting for it too get close will result in the tentacles hitting you",
        "Twin Lizards (Top Right): Stay as high up as possible.  Dropping down for too long is death",
        "Other bosses are pretty straight forward",
        "For the final boss (horse section) -> Very straight forward except for the skullbat section where you need to let the boss fly ahead of you and then when it does it's ground attack, you'll need to run full speed to get ahead before it does the attack again.  If you do it properly you'll just barely make it",
      ]
    ),
    new gameNote(
      "Fallout 3 (GFWL)",
      1,
      "https://i.imgur.com/6iPfMXb.png",
      "https://www.trueachievements.com/game/Fallout-3-PC/achievements",
      "Fallout-3-PC",
      false,
      false,
      false,
      true,
      [
        "If you have this game on Steam you will need a valid GFWL key for any game in order to access if you don't have the Fallout 3 key",
        "This guide shows how to get an older build of the game on Steam before GFWL was patched out",
        "https://www.trueachievements.com/forum/viewthread.aspx?tid=1380361",
      ]
    ),
    new gameNote(
      "Citizen Sleeper",
      2,
      "http://store-images.s-microsoft.com/image/apps.10588.14019812399166283.dc5d064e-3693-46fa-896f-9287938c5df4.9066278e-60b2-4859-bcf7-64b239159dd8",
      "https://www.trueachievements.com/game/Citizen-Sleeper/achievements",
      "Citizen-Sleeper",
      false,
      false,
      false,
      false,
      [
        "Don't worry about all the missables. You can do most achievements in one playthrough if you're generally aware of how the different paths work.  The gist is whenever you are presented the option to leave the station - don't",
        "On my first playthrough I got all the missable achievements in the game except 'Solo Ticket' and 'Three Body Problem'.  The few remaining missables left over after your first playthrough can be easily speedran.  It can't be stressed enough how easy this game becomes once you understand the underlying game mechanics making it a breeze to speedrun so don't sweat missing achievements",
      ]
    ),
    new gameNote(
      "The Gunk",
      2,
      "http://store-images.s-microsoft.com/image/apps.38178.14466083566107896.f1b98327-0240-4097-b759-cc5bd89ee66e.c08f0078-5fb3-4eaf-b154-c6c8c9a3ab6f",
      "https://www.trueachievements.com/game/The-Gunk/achievements",
      "The-Gunk",
      false,
      false,
      false,
      false,
      [
        "Only annoying thing to be aware of is the 'Full House' achievement for scanning every scannable object.  Because this game is a linear story game, once you progress past certain levels you can't go back so all collectible related achievements are considered missable",
      ]
    ),
    new gameNote(
      "Shadow Warrior 3: Definitive Edition",
      2,
      "http://store-images.s-microsoft.com/image/apps.22203.14410885252967132.1b3ee644-b39e-4fc5-a7d6-75e984a1c756.fe60491a-8da0-4b31-b452-01590b69552b",
      "https://www.trueachievements.com/game/Shadow-Warrior-3/achievements",
      "Shadow-Warrior-3",
      false,
      false,
      false,
      false,
      [
        "No missables or difficulty related achievements",
        "The unlock all upgrades achievement requires you to get all collectibles so use a walkthrough to get this in one playthrough",
        "Final cleanup can be done via level select and just farming it out on specific levels (for finishers etc.)",
      ]
    ),
    new gameNote(
      "Ravenlok",
      2,
      "http://store-images.s-microsoft.com/image/apps.35274.13630560684125266.976bb40c-eec2-4cc9-ae70-59cfcd8d70a6.ea417223-5fcc-46c7-b74f-05a2e82e8beb",
      "https://www.trueachievements.com/game/Ravenlok/achievements",
      "Ravenlok",
      false,
      false,
      false,
      false,
      ["Can be done blind just use TA solutions for cleanup"]
    ),
    new gameNote(
      "Sunset Overdrive (Windows)",
      3,
      "http://store-images.s-microsoft.com/image/apps.55299.13736301732248840.1e2d854a-e5e9-4c32-b8a1-2630937d19b9.03244896-e61b-4b5b-b089-cb85018ff10a",
      "https://www.trueachievements.com/game/Sunset-Overdrive-Windows/achievements",
      "Sunset-Overdrive-Windows",
      false,
      false,
      false,
      false,
      [
        "Simplier achievement list than the Xbox version",
        "Had a corrupted save so would recommend making multiple saves when playing",
      ]
    ),
    new gameNote(
      "Turnip Boy Commits Tax Evasion",
      1,
      "http://store-images.s-microsoft.com/image/apps.20697.13918938342247004.3a4e9214-ba82-4550-b1da-17c3eefbdfa4.141fd461-96de-40d4-8464-1c3096ff92bd",
      "https://www.trueachievements.com/game/Turnip-Boy-Commits-Tax-Evasion/achievements",
      "Turnip-Boy-Commits-Tax-Evasion",
      false,
      false,
      false,
      false,
      ["Refer to TA solutions if you get stuck on certain quests"]
    ),
    new gameNote(
      "Death's Door",
      3,
      "http://store-images.s-microsoft.com/image/apps.37438.13892871097033903.6bf68087-15c2-4acb-aa8d-2a9356aea7ff.e6624c21-964f-4cd0-85bd-650a80e2b348",
      "https://www.trueachievements.com/game/Deaths-Door/achievements",
      "Deaths-Door",
      false,
      false,
      false,
      false,
      [
        "Decent amount of collectables and a few missables so definitely use the TA walkthrough",
        "Has Souls-like combat that is hardest at the beginning of the game since you won't have any spells or upgrades but once you start progressing it becomes significantly easier",
      ]
    ),
    new gameNote(
      "Sleeping Dogs: Definitive Edition",
      3,
      "http://store-images.s-microsoft.com/image/apps.62544.69516029925336975.e3cb0155-71cd-4799-bed4-63da86cce109.4c58c9ac-af1c-4561-8eb6-113fd5f47221",
      "https://www.trueachievements.com/game/Sleeping-Dogs-Definitive-Edition/achievements",
      "Sleeping-Dogs-Definitive-Edition",
      false,
      false,
      false,
      false,
      [
        "No missables or difficulty related achievements",
        "Collectibles appear on the map after progressing through the game so no need for a walkthrough",
        "Same achievement list as the 360 version",
      ]
    ),
    new gameNote(
      "Dead Space (2008)",
      3,
      "http://store-images.s-microsoft.com/image/apps.9607.69768431406041041.e1386476-b377-413b-9457-1f5559dbb168.345050eb-2861-4fc1-9bdb-e7dcc7965eea",
      "https://www.trueachievements.com/game/Dead-Space-Xbox-360/achievements",
      "Dead-Space-Xbox-360",
      false,
      false,
      false,
      false,
      [
        "Follow TA walkthrough plus in-game cheats (which the walkthrough explains how to use) makes this one pretty easy",
        "For the infamous asteroid achievement turn up the game brightness to max.  Makes it way easier to see asteroids",
      ]
    ),
    new gameNote(
      "Portal: Still Alive",
      2,
      "http://store-images.s-microsoft.com/image/apps.47106.68905864232445372.81cdd228-6f30-4af2-b5e7-c2ed07a21d3d.e273d275-3934-41bd-b856-ae51027fa1af",
      "https://www.trueachievements.com/game/Portal-Still-Alive/achievements",
      "Portal-Still-Alive",
      false,
      false,
      false,
      false,
      [
        "Just refer to TA solutions for anything you are stuck on and solutions for puzzle rooms",
      ]
    ),
    new gameNote(
      "Diablo III: Reaper of Souls - Ultimate Evil Edition",
      3,
      "http://store-images.s-microsoft.com/image/apps.56115.66154479819523742.0dd42547-0394-489e-b8a8-0bfec963dfd8.b15fb871-6679-495e-bfba-0fb7c855d4f0",
      "https://www.trueachievements.com/game/Diablo-III-Reaper-of-Souls-Ultimate-Evil-Edition-Xbox-360/achievements",
      "Diablo-III-Reaper-of-Souls-Ultimate-Evil-Edition-Xbox-360",
      false,
      false,
      false,
      false,
      [
        "Differs from original game since this features the necromancer DLC and an additional Act",
        "Can import you previous save data from base Diablo 3 making the get 7 classes to level 60 easy since you only have to do necromancer",
      ]
    ),
    new gameNote(
      "LA Cops",
      3,
      "http://store-images.s-microsoft.com/image/apps.40910.69123253299542418.026a4d66-930c-45bf-ab1e-dce58dd5e2fb.8406dee3-9a1e-40c2-8a92-070bbf26515c",
      "https://www.trueachievements.com/game/LA-Cops/achievements",
      "LA-Cops",
      false,
      false,
      false,
      false,
      [
        "Very similar to Hotline Miami where you die in one hit",
        "The gameplay takes some getting use to because you have to be very defensive but once you get in the grove it's smooth sailing",
      ]
    ),
    new gameNote(
      "Haven",
      2,
      "http://store-images.s-microsoft.com/image/apps.63125.14474015592034459.e2e750cd-a461-4824-ad07-a9b8c0e9bdfb.87aadc5d-df35-491f-9931-2c5dc2ac5250",
      "https://www.trueachievements.com/game/Haven/achievements",
      "Haven",
      false,
      false,
      false,
      false,
      [
        "No difficulty requirements so you can just put it on the easiest difficulty and the only missable (Don't get KO'd) is super easy to complete because of this",
        "The game autosaves right before the final decision at the end of the game so you can easily get both achievements for completing each ending without worrying",
      ]
    ),
    new gameNote(
      "Call of the Sea",
      2,
      "http://store-images.s-microsoft.com/image/apps.62111.13743362462149476.fca1cf48-9b03-4004-a824-97fd70c4c919.3c1dc9e6-ec37-493d-ad39-ce971b4a43b1",
      "https://www.trueachievements.com/game/Call-of-the-Sea/achievements",
      "Call-of-the-Sea",
      false,
      false,
      false,
      false,
      [
        "Just follow TA walkthrough especially for some of the later puzzle sections",
      ]
    ),
    new gameNote(
      "Hellblade: Senua's Sacrifice",
      2,
      "http://store-images.s-microsoft.com/image/apps.51441.67453348098260763.a9f96429-c651-425e-97d2-e8861561f15f.73c36cb3-fa0c-4b94-9cdf-abc1f2037096",
      "https://www.trueachievements.com/game/Hellblade-Senuas-Sacrifice/achievements",
      "Hellblade: Senua's Sacrifice",
      false,
      false,
      false,
      false,
      ["Just follow TA walkthrough"]
    ),
    new gameNote(
      "Minecraft: Xbox One Edition",
      3,
      "http://store-images.s-microsoft.com/image/apps.23039.70752356936699695.515eec1c-0b8d-49fe-bcee-b432978cd8f4.d1385a3e-3490-4586-9332-b679bfab0ed1",
      "https://www.trueachievements.com/game/Minecraft-Xbox-One-Edition/achievements",
      "Minecraft-Xbox-One-Edition",
      false,
      false,
      false,
      false,
      [
        "Different from the current edition of Minecraft since this one is no longer getting updates and has a different achievement list",
        "Game is incompatible with achievement worlds of the current version of Minecraft so you will need to do it legit",
        "Has some competitive mulitplayer achievements that can be boosted with three controllers",
      ]
    ),
    new gameNote(
      "Titanfall 2",
      4,
      "http://store-images.s-microsoft.com/image/apps.33826.65123785197113223.0191c6ed-9ff7-488b-b8cc-c338d3886461.43c5a5b6-584f-4b73-96a3-48c6648c4784",
      "https://www.trueachievements.com/game/Titanfall-2/achievements",
      "Titanfall-2",
      true,
      false,
      false,
      false,
      [
        "Has an infamous speedrun achievement which has become widely disliked due to it's difficulty.  Personally I didn't have that much trouble with it compared to others (definitely took at least ~2 hours practice for me though).  Lots of guides and recordings of how people approached this so definitely take a look if you're having trouble",
        "Because of the difficulty (and ratio on TA for the speedrun achievement alone) I am still gonna give Titanfall 2 the avoid tag as more of a warning for others",
      ]
    ),
    new gameNote(
      "Sea of Solitude",
      2,
      "http://store-images.s-microsoft.com/image/apps.2988.66899389918915343.3cdfd82d-2868-4f03-99bf-197a61c1cda4.5f445c31-8f82-4689-a504-411b84606add",
      "https://www.trueachievements.com/game/Sea-of-Solitude/achievements",
      "Sea-of-Solitude",
      false,
      false,
      false,
      false,
      [
        "Just follow TA walkthrough",
        "Missed collectibles can be level selected",
      ]
    ),
    new gameNote(
      "Diablo 3",
      4,
      "https://i.imgur.com/maIvmUy.png",
      "https://www.trueachievements.com/game/Diablo-3/achievements",
      "Diablo 3",
      false,
      false,
      false,
      false,
      [
        "Basically just getting each class to level 60 and one to level 30 on hardcode",
        "Most of your time will be spent griding out 100 treasure goblins and reaching 5,000,000 gold",
      ]
    ),
    new gameNote(
      "Assassin's Creed II",
      2,
      "http://store-images.s-microsoft.com/image/apps.2346.67608528792882784.56fd0e59-8e5c-4c63-9eca-864b4e57fa73.7ec02e56-9921-4204-be2c-b60efdc784ba",
      "https://www.trueachievements.com/game/Assassins-Creed-2/achievements",
      "Assassins-Creed-2",
      false,
      false,
      false,
      false,
      [
        "Make sure to be aware of the one missable since your one chance to get it is a decent amount of the way through the game",
      ]
    ),
    new gameNote(
      "Star Wars Jedi: Fallen Order",
      2,
      "http://store-images.s-microsoft.com/image/apps.52300.65392999590663672.028b6875-f925-4d40-b3a1-e44db3b4fa32.7bcb3d0f-46fc-43ea-84d1-031bd0817da2",
      "https://www.trueachievements.com/game/Star-Wars-Jedi-Fallen-Order/achievements",
      "Star-Wars-Jedi-Fallen-Order",
      false,
      false,
      false,
      false,
      [
        "No missables or difficulty related achievements so you can do this one mostly blind",
      ]
    ),
    new gameNote(
      "NieR: Automata BECOME AS GODS Edition",
      2,
      "http://store-images.s-microsoft.com/image/apps.33318.68662117307814849.d86c4ebf-4ee7-4307-bce4-30e6169169a8.7a80f06e-fae2-43ab-a302-22a63942b3aa",
      "https://www.trueachievements.com/game/NieR-Automata/achievements",
      "NieR-Automata",
      false,
      false,
      false,
      false,
      [
        "You can unlock certain achievements by buying them with ingame currency in the secret shop",
        "Despite this is will still require multiple playthroughs",
      ]
    ),
    new gameNote(
      "Assassin's Creed",
      2,
      "http://store-images.s-microsoft.com/image/apps.47824.64148876922571625.4747b43e-3d86-49fc-aa97-ee00c70af4e5.263a3060-8cb8-4299-9028-a9f94150d516",
      "https://www.trueachievements.com/game/Assassins-Creed/achievements",
      "Assassins-Creed",
      false,
      false,
      false,
      false,
      [
        "Sole missable is very easy to mess up so be careful about always exhausting all of Lucy's dialog options whenever you get the chance between missions",
      ]
    ),
    new gameNote(
      "Tacoma",
      1,
      "http://store-images.s-microsoft.com/image/apps.3615.14580847963455900.277186a0-7763-4237-8a63-6694f941f9f2.a29ba789-8e84-49c8-b2b1-0e2eb181b655",
      "https://www.trueachievements.com/game/Tacoma/achievements",
      "Tacoma",
      false,
      false,
      false,
      false,
      ["Just follow TA walkthrough"]
    ),
    new gameNote(
      "Darksiders Genesis",
      3,
      "http://store-images.s-microsoft.com/image/apps.57278.69448640791975811.c544d438-5a2e-4e68-96d0-33f88c057181.c01695d1-d77f-435a-a275-56a6575b0836",
      "https://www.trueachievements.com/game/Darksiders-Genesis/achievements",
      "Darksiders-Genesis",
      false,
      false,
      false,
      false,
      [
        "Will require multiple playthroughs to be able to do Apocalyptic Difficulty",
      ]
    ),
    new gameNote(
      "Mini Ninjas",
      2,
      "http://store-images.s-microsoft.com/image/apps.9748.14279660117629388.28340bea-a957-4705-a173-a63764247caa.5fcc1982-d9fa-40d8-b326-f52ae2a20177",
      "https://www.trueachievements.com/game/Mini-Ninjas/achievements",
      "Mini-Ninjas",
      false,
      false,
      false,
      false,
      [
        "Only difficulty achievement is still pretty easy to do so don't worry about starting out on 'Hard' difficulty",
        "No missables so everything can be cleaned up after completing the story",
      ]
    ),
    new gameNote(
      "Children of Morta",
      2,
      "http://store-images.s-microsoft.com/image/apps.6560.14624995180811495.9dbd2531-e4d7-4936-8569-0143963fdb26.a3ca038e-d3d7-4f14-8b91-1003bd6acd22",
      "https://www.trueachievements.com/game/Children-of-Morta/achievements",
      "Children-of-Morta",
      false,
      false,
      false,
      false,
      [
        "A bit tricky and grindy at first but once you start upgrading your characters more and more you quickly become very overpowered and the game becomes trivial",
        "Has a few local mulitplayer achievements that are easy to do",
      ]
    ),
    new gameNote(
      "New Super Lucky's Tale",
      2,
      "http://store-images.s-microsoft.com/image/apps.30130.13867280688858311.8624d86c-7857-440e-9303-9897a29ba67e.4154a1f1-dd43-48fb-a3cb-b8638226e6b7",
      "https://www.trueachievements.com/game/New-Super-Luckys-Tale/achievements",
      "New-Super-Luckys-Tale",
      false,
      false,
      false,
      false,
      [
        "Almost identical to the older version of the game but without the grindy achievements making the completion much easier",
      ]
    ),
  ];
}
